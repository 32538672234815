import theme from "@studytools/core/ui/theme/theme";
import React, { FC, ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "../../../styles/GlobalStyles";

type Props = {
  children: ReactNode;
};

const Layout: FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export default Layout;
